import React from "react";

import { PageProps } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import ArtistProfile from "../../static/images/beverly.jpg";
import SelectedArt from "../../static/images/southwestern/southwestern-28.jpg";


const AboutPage = (props: PageProps) => (
  <Layout>
    <SEO title="About" />

    <div className="content_container">
      <Typography className="page_header" component="h1" variant="h3" paragraph>
        Sacred Landscape Paintings
      </Typography>
      
      <Grid container spacing={2}>
        <Grid item md={12} lg={4} xl={3}>
          <img src={SelectedArt} />
        </Grid>

        <Grid item md={12} lg={8} xl={9}>
          <Typography component="p" paragraph>
            Painting the landscape has always been as much a spiritual expression as a representation of beauty for me. 
            It is a celebration of the Earth and its cycles in nature, as well as a reverence towards the Creator of this life. 
            The four essential elements of Earth, Water, Air and Fire play heavily in my interpretations of the locations that I paint. 
            While I find inspiration in every corner of the globe, I am particularly drawn to the American West. Its vistas seem to emanate all that is Divine on this Earth. 
          </Typography>

          <Typography component="p" paragraph>
            The use of gold leaf in depictions of the spiritual in Art dates back hundreds of years. In an homage to this practice, 
            I often use gold, silver or copper leafing as a ground for my oil paintings. As a result, the paintings take on a reverential tone, while also creating a sense of movement. 
          </Typography>
        </Grid>

        <Grid container spacing={2} className="photo_row_reverse">
          <Grid item md={12} lg={8} xl={9}>
            <Typography component="p" paragraph>
              Mainly a studio painter, I paint from photographs and memories of the places I have lived and visited. 
              Friends and relatives also share their images with me, which enables me to celebrate places through their eyes and experiences. 
              I also work from photographs on a commission basis. I will gladly paint one of your favorite places for you! Contact me through this page to discuss the creation of your special memory. 
            </Typography>
          </Grid>

          <Grid item md={12} lg={4} xl={3}>
            <img src={ArtistProfile} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  </Layout>
)

export default AboutPage;
